export default {
    menu: {
        platform: 'FinTax Cryptocurrency Tax Tool',
        services: 'Consulting Services',
        content: 'Insights & Analysis',
        depth: 'In-depth Analysis',
        compile: 'Compile',
        events: 'Commentaries & Activities',
        about: 'About Us',
        introduce: 'Introduce',
        join: 'Join us',
        reprinted: 'Repost Inquiry',
        contact: 'Contact us',
        all: 'All'
    },
    about: {
        name: "About Us",
        title: "In recent years, with the regularization of digital assets, their tax-related aspects have become a hot topic in the industry.",
        content: "In 2021, the cryptocurrency tax software TaxBit completed a $130 million Series B financing, with a valuation exceeding $1.3 billion, making it a unicorn enterprise. Various countries have successively enacted legislation addressing taxation on activities such as cryptocurrency mining and trading. Internationally, a cross-border regulatory framework for cryptocurrency assets is gradually being established, and the taxation of cryptographic assets is shifting from incidental to inevitable. Against this backdrop, the topic of how compliant digital asset investors and institutions can strategically structure their operations and enhance tax compliance has become a focal point.",
        introduceTitle: "我们希望通过成立 TaxDAO 去帮助社区更好地应对税务合规和税务问题，也希望能通过我们的专业能力，帮助社区走出相对清晰的税务合规路径，从而弥合税务监管和行业之间的 gap，如果在此之上能一定程度推动有关立法的进程，收集信息和需求并发声可能对行业是更加有意义有价值的事情。",
        introduceContent: "我们希望用DAO这种开放平台的形式更多的聚集对于“加密货币行业&税”感兴趣的专业人士和从业者，共同帮助行业理顺合规路径，并且在目前行业税务监管相对早期的阶段进行一些基础研究和建设以帮助行业未来的合规发展。",
    },
    baseInfo: {
        telegram: 'Telegram',
        telegraphGroup: 'TaxDAO Information group',
        mail: 'E-mail',
        wechat: 'Wechat',
        copyright: 'copyright',
    },
    titles: {
        more: 'Learn More',
        sale: 'sale',
        case: 'Consult the business case',
        news: 'News',
        selected: 'Curated Collection',
        pay: 'Pay-to-Read',
        recommend: 'Recommend read',
        contact: 'You can contact us below',
        looKAll: 'More',
    },
    articleType: {
        pay: 'Pay',
        recommend: 'Recommend',
    },
    articles: {
        date: 'Released',
        read: 'Reads',
        gather: 'Collection',
        author: 'Author',
        summary: 'Abstract',
        keyword: 'Keyword',
        original: 'Pay to view',
        pay: 'Go and pay',
        cancel: 'Cancel',
        username: 'Username',
        captcha: 'Captcha',
        send: 'Send Captcha',
        share: 'Share',
        search: 'Search the TaxDAO...'
    },
    services: {
        title: "Diverse and Complementary Team of Experts: TaxDAO combines extensive knowledge and practical experience in cryptocurrencies, tax processing and regulatory compliance, as well as actively exploring the latest developments in the cryptocurrency market.",
        content: "Providing efficient and safe tax solutions: Our pluralistic, professional and frontier team ensures that we can provide you with efficient, safe and trustworthy tax solutions, proposing precise strategies for tax-related issues, providing a robust way to increase wealth for high-net-worth individuals, enhancing the operational efficiency of enterprises and safeguarding legitimate tax rights and interests.",
        caseDes: "Consultation Case"
    },
    share: {
        twitter: 'twitter',
        telegram: 'telegram',
        wechat: 'wechat',
        weibo: 'weibo',
    },
    test: "test",
    // 导航
    menuHome: "FinTax Cryptocurrency Tax Tool",
    menuServices: "Consulting Services",
    menuContent: "Insights & Analysis",
    menuContentDepth: "In-depth Analysis",
    menuContentCompile: "Compile",
    menuContentEvents: "Commentaries & Activities",
    menuAbout: "About Us",
    menuAboutIntroduce: "About TaxDAO",
    menuAboutJoin: "Join TaxDAO",
    menuAboutConsult: "Repost Inquiry",
    menuAboutContact: "Advantages of TaxDAO",

    //footer
    copyRight: "Copyright ©2024 FinTax Technology Limited.",
    footerDes: "Privacy Policy ｜ Terms of use & legal notices",
};
