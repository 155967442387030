import {createRouter, createWebHashHistory} from 'vue-router'

const routes = [
    { path: '/', redirect: '/content' },
    {
        path: '/home', name: 'home', component: () => import(/* webpackChunkName: "about" */ '../views/home/index.vue')
    },
    {
        path: '/services',
        name: 'services',
        component: () => import(/* webpackChunkName: "about" */ '../views/services/index.vue')
    },
    {
        path: '/content',
        name: 'content',
        component: () => import(/* webpackChunkName: "about" */ '../views/content/index.vue'),
        children: [
            {
                path: '/content',
                component: () => import(/* webpackChunkName: "about" */ '../views/content/content.vue'),
            },
            {
                path: 'all',
                component: () => import(/* webpackChunkName: "about" */ '../views/content/all.vue'),
            },
            {
                path: 'other',
                component: () => import(/* webpackChunkName: "about" */ '../views/content/other.vue'),
            }
        ]
    },
    {
        path: '/about',
        name: 'about',
        component: () => import(/* webpackChunkName: "about" */ '../views/about/index.vue'),
    },
    {
        path: '/articles',
        name: 'articles',
        component: () => import(/* webpackChunkName: "about" */ '../views/articles/index.vue'),
    },
    {path:'/result',component:()=>import('../views/Result.vue')}, // 支付结果
    {path:'/dropIn',component:()=>import('../views/dropIn.vue')}, // 支付结果
]

const router = createRouter({
    history: createWebHashHistory(), routes
})

export default router
