<template>
  <footer class="bg-dark text-white">
    <div
        class="container-xxl px-3_5 px-md-7 pt-5 py-3 d-flex flex-column flex-column-reverse justify-content-between flex-md-row">
      <div class="d-flex flex-column align-items-center">
        <img class="logo" src="~@/assets/footer/logo-white.svg">
        <p class="text-center  copy-right mt-3 mt-md-4">{{ $t("copyRight") }}</p>
      </div>

      <div class="d-flex flex-column align-items-center">
        <div class="icon-links row">
          <div class="col-3 col-md text-center mb-3" v-for="(iconLink, index) in iconLinks" :ref="iconLink.type" :key="index"
               @click="openLink(iconLink)">
            <img v-if="iconLink.type != 'wx'" :src="iconLink.icon" :alt="iconLink.name">
            <el-popover
                v-else
                placement="top"
                :width="200"
                trigger="click"
                content="this is content, this is content, this is content"
            >
              <template #reference>
                <img :src="iconLink.icon" :alt="iconLink.name">
              </template>
              <img class="w-100" src="~@/assets/about/qrcode.jpg" >
            </el-popover>
          </div>
        </div>
        <p class="text-center mt-2 mb-0">{{ $t("footerDes") }}</p>

      </div>
    </div>
  </footer>
</template>

<script setup>
import Store from "@/store/index.js";
import Icon1 from "@/assets/footer/icon-1.svg";
import Icon2 from "@/assets/footer/icon-2.svg";
import Icon3 from "@/assets/footer/icon-3.svg";
import Icon4 from "@/assets/footer/icon-4.svg";
import Icon5 from "@/assets/footer/icon-5.svg";
import Icon6 from "@/assets/footer/icon-6.svg";
import Icon7 from "@/assets/footer/icon-7.svg";

const iconLinks = [
  {
    icon: Icon1,
    name: "substack",
    urlEn: "https://taxdaodc.substack.com/",
    urlCn: "https://taxdao.substack.com/"
  },
  {
    icon: Icon2,
    name: "领英",
    urlEn: "https://www.linkedin.com/company/fintaxdc/",
    urlCn: "https://www.linkedin.com/company/fintaxdc/"
  },
  {
    icon: Icon3,
    name: "频道",
    urlEn: "t.me/TaxDAOEN",
    urlCn: "t.me/TaxDAODC"
  },
  {
    icon: Icon4,
    name: "社群",
    urlEn: "http://t.me/TaxDAO_ENchat",
    urlCn: "http://t.me/TaxDAOinfo"
  },
  {
    icon: Icon5,
    name: "推特",
    urlEn: "https://x.com/TaxDAO_DC",
    urlCn: "https://x.com/FinTax_CN"
  },
  {
    icon: Icon6,
    name: "微信",
    type: "wx",
  },
  {
    icon: Icon7,
    name: "邮箱",
    type: "email",
    url: "contact@fintax.tech"
  }
]

function openLink(item) {
  if (item.type === "email") {
    window.open(`mailto:${item.url}`, "_blank");
  } else if (item.type !== "wx") {
    window.open(Store.state.lang == 1 ? item.urlCn : item.urlEn, "_blank");
  }
}
</script>

<style lang="scss" scoped>
footer {
  font-size: 0.625rem;

  .logo {
    width: auto;
    height: 1.75rem;
  }

  .icon-links img {
    width: 3.125rem;
    height: 1.85rem;
    cursor: pointer;
  }
}
</style>
