export default {
    menu: {
        platform: 'FinTax加密货币税务工具',
        services: '咨询服务',
        content: '内容',
        depth: '深度',
        compile: '编译',
        events: '评论和活动',
        about: '关于我们',
        introduce: 'TAXDAO简介',
        join: '加入我们',
        reprinted: '咨询转载',
        contact: '联系我们',
        all: '全部'
    },
    about: {
        name: "关于我们",
        title: "近年来随着加密货币的合规化，其税务相关内容也成为行业热点。",
        content: "2021年加密货币税务软件TaxBit 完成 1.3 亿美元B轮融资，估值超 13 亿美元，成为独角兽企业。各个国家都相继立法，针对加密货币挖矿、交易等出台相应的税法。中国也在2022年针对一些大户、矿工征税，未来也许会从偶然转为必然。在这样的大背景下，加密货币投资者、机构如何合理配置资产、完善税务合规，也成为了一个热点话题。",
        introduceTitle: "我们希望通过成立 TaxDAO 去帮助社区更好地应对税务合规和税务问题，也希望能通过我们的专业能力，帮助社区走出相对清晰的税务合规路径，从而弥合税务监管和行业之间的 gap，如果在此之上能一定程度推动有关立法的进程，收集信息和需求并发声可能对行业是更加有意义有价值的事情。",
        introduceContent: "我们希望用DAO这种开放平台的形式更多的聚集对于“加密货币行业&税”感兴趣的专业人士和从业者，共同帮助行业理顺合规路径，并且在目前行业税务监管相对早期的阶段进行一些基础研究和建设以帮助行业未来的合规发展。",
    },
    baseInfo: {
        telegram: 'Telegram',
        telegraphGroup: 'TaxDAO Information group',
        mail: '邮箱',
        wechat: '微信',
        copyright: '版权所有',
    },
    titles: {
        more: '查看更多',
        sale: '月销',
        case: '咨询业务案例',
        news: '最新资讯',
        selected: '精选合集',
        pay: '付费阅读',
        recommend: '推荐阅读',
        contact: '可通过以下方式联系我们',
        looKAll: '查看全部',
    },
    articleType: {
        pay: '付费',
        recommend: '推荐',
    },
    articles: {
        date: '发布时间',
        read: '阅读数',
        gather: '合集',
        author: '作者',
        summary: '摘要',
        keyword: '关键词',
        original: '付费查看原文',
        pay: '去支付',
        cancel: '取消',
        username: '用户名',
        captcha: '验证码',
        send: '发送验证码',
        share: '分享',
        search: '请输入你要搜索的文章'
    },
    services: {
        title: "多元且互补的专家团队。TaxDAO的专家团队整合了加密货币、税务处理和合规监管的丰富知识与实践经验。积极探索加密货币市场的最新动态。",
        content: "提供高效、安全的财税解决方案。多元、专业而前沿的团队确保了我们能为您提供高效、安全、可信赖的财税解决方案，针对涉税问题提出精准策略，为高净值个人提供稳健的财富增值途径，提升企业运营效益，保障合法税收权益。",
        caseDes: "咨询业务案例"
    },
    share: {
        twitter: 'twitter',
        telegram: 'telegram',
        wechat: '微信',
        weibo: '微博'
    },
    test: "测试",
    // 导航
    menuHome: "FinTax加密货币税务工具",
    menuServices: "咨询服务",
    menuContent: "内容",
    menuContentDepth: "深度",
    menuContentCompile: "编译",
    menuContentEvents: "评论和活动",
    menuAbout: "关于我们",
    menuAboutIntroduce: "TaxDAO简介",
    menuAboutJoin: "加入TaxDAO",
    menuAboutConsult: "咨询转载",
    menuAboutContact: "TaxDAO的优势",

    //footer
    copyRight: "Copyright ©2024 FinTax Technology Limited.",
    footerDes: "Privacy Policy ｜ Terms of use & legal notices",
};
